<template>
  <section class="py-7 text-center">
    <div class="container">
      <i class="isologo mb-2"></i>
      <h4 class="text-dark mb-5">{{ $lang("levels.title", true) }}</h4>

      <div class="row mb-6">
        <div
          class="col-sm-6 col-lg-2"
          v-for="l of $lang('levels.items', true)"
          :key="l.name"
        >
          <div class="level-item" :class="`bg-${l.color}`">
            <p class="title">{{ l.name }}</p>
            <p>{{ l.title }}</p>
          </div>
        </div>
      </div>

      <p class="text-tertiary max-w-4">
        <b>{{ $lang("levels.subtitle", true) }}</b>
      </p>
    </div>
  </section>

  <section class="py-7">
    <div class="container">
      <div
        class="level-detail-item"
        v-for="l of $lang('levels.items', true)"
        :key="l.name"
      >
        <div class="header" :class="`bg-${l.color}`">
          <p class="title">{{ l.name }}</p>
          <p class="subtitle">{{ l.title }}</p>
          <div class="badge">
            {{ l.hours }} {{ $lang("levels.hours", true) }}
          </div>
        </div>
        <div class="content">
          <p>
            <b :class="`text-${l.color}`"
              >{{ $lang("levels.courses", true) }}
            </b>
            {{ l.courses }}
          </p>
          <p class="mb-4">
            <b :class="`text-${l.color}`"
              >{{ $lang("levels.diploma", true) }}
            </b>
            {{ l.diploma }}
          </p>
          <p>
            {{ l.details }}
          </p>
        </div>
      </div>

      <div class="mt-7">
        <slot />
      </div>
    </div>
  </section>
</template>

<style scoped>
.swiper-gallery {
  overflow: hidden !important;
  padding-bottom: 2em;
  --pagination-bottom: 0;
}
</style>
